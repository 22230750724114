<template>
    <div class="baseMain">
        <div class="systemSchool">
            <el-form label-width="200px" size="large">
                <el-divider>学生信息管理</el-divider>
                <el-form-item label="基本信息">
                    <el-tag class="elTagClass" v-for="item in jibenxinxi" :key="item.index">{{item.name}}</el-tag>
                </el-form-item>
                <el-form-item label="证件信息">
                    <el-tag class="elTagClass" v-for="item in zhengjianxinxi" :key="item.index">{{item.name}}</el-tag>
                </el-form-item>
                <el-form-item label="其他信息">
                    <el-tag class="elTagClass" v-for="item in qitaxinxi" :key="item.index">{{item.name}}</el-tag>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="openEdit">修改</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-dialog title="修改学校基础信息" v-model="dialogVisible" width="600px">
            <el-form :model="ruleForm" ref="ruleForm" label-width="auto">
                <el-form-item label="基本信息">
                    <el-checkbox v-model="item.show" v-for="item in jibenxinxi" :key="item.index"
                                 :label="item.name"></el-checkbox>
                </el-form-item>
                <el-form-item label="证件信息">
                    <el-checkbox v-model="item.show" v-for="item in zhengjianxinxi" :key="item.index"
                                 :label="item.name"></el-checkbox>
                </el-form-item>
                <el-form-item label="其他信息">
                    <el-checkbox v-model="item.show" v-for="item in qitaxinxi" :key="item.index"
                                 :label="item.name"></el-checkbox>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "systemStudent",
        data() {
            return {
                jibenxinxi: [
                    {
                        key: 'a1',
                        name: '学工号',
                        show: true
                    },
                    {
                        key: 'a2',
                        name: '姓名',
                        show: true
                    },
                    {
                        key: 'a3',
                        name: '性别',
                        show: true
                    },
                    {
                        key: 'a4',
                        name: '班级',
                        show: true
                    },
                    {
                        key: 'a5',
                        name: '联系方式',
                        show: true
                    },
                ],
                zhengjianxinxi: [
                    {
                        key: 'b1',
                        name: '国籍',
                        show: true
                    },
                    {
                        key: 'b2',
                        name: '证件类型',
                        show: true
                    },
                    {
                        key: 'b3',
                        name: '证件号码',
                        show: true
                    },
                ],
                qitaxinxi: [
                    {
                        key: 'c1',
                        name: '宿舍号',
                        show: true
                    },
                    {
                        key: 'c2',
                        name: '家庭住址',
                        show: true
                    },
                    {
                        key: 'c3',
                        name: '民族',
                        show: true
                    },
                    {
                        key: 'c4',
                        name: '家长信息',
                        show: true
                    },
                ],
                dialogVisible: false,
            }
        },
        methods: {
            openEdit() {
                this.dialogVisible = true;
            }
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .systemSchool {
        width: 800px;
        box-sizing: border-box;
    }

    .elTagClass {
        margin-right: 10px;
    }

</style>
